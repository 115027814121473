import React from 'react'
import './Footer.css'
import footer_logo from '../Assets/logo_big.png'
import instagram_icon from '../Assets/instagram_icon.png'
import whatsapp_icon from '../Assets/whatsapp_icon.png'
export const Footer = () => {
  return (
    <div className='footer'>
        <div className="footer-logo">
            <img src={footer_logo} alt="" />
            <p>Aramot Skincare Cosmetics</p>
        </div>
        <ul className="footer-links">
            <li>Products</li>
            <li>Officies</li>
            <li>About</li>
            <li>Contact</li>
        </ul>
        <div className="footer-social-icon">
            <div className="footer-icons-container">
                <a href="https://www.instagram.com/aramot_skincare_alhajaramat/" target='blank'>
                    <img src={instagram_icon} alt="instagram logo" />
                </a>
            </div>
            <div className="footer-icons-container">
                <a href="https://wa.me/message/PX4XVKVSYLCAA1">
                    <img src={whatsapp_icon} alt="whatsapp logo" />
                </a>
            </div>
        </div>
        <div className="footer-copyright">
            <hr/>
            <p>Copyright @ 2024 - All Right Reserved</p>
        </div>
    </div>
  )
}
