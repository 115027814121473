import React, { useState } from 'react'
import './CSS/LoginSignup.css'
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export const LoginSignup = () => 
{
    const [state, setState] = useState("Login");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [formData, setFormData] = useState(
    {
        firstName:"",
        lastName:"",
        password:"",
        email:""
    });

    const [errors, setErrors] = useState(
    {
        name: "",
        password: "",
        email: ""
    });

    const changeHandler = (e) =>
    {
        setFormData({...formData, [e.target.name]:e.target.value});
    }

    const togglePasswordVisibility = () =>
    {
        setPasswordVisible(!passwordVisible);
    }

    const login = async () =>
    {
        console.log("Login Function Executed", formData);
        let responseData;
        await fetch('https://golden-union-441323-d5.de.r.appspot.com/users/login',
        {
            method: 'POST',
            headers: 
            {
                Accept: 'application/form-data',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        }).then((response) => response.json())
        .then((data) => responseData = data);

        if (responseData.success)
        {
            localStorage.setItem('auth-token', responseData.token);

            // Storing ID in the local storage for when the user wants to change profile details
            localStorage.setItem('userId', responseData.id);

            // Go to home page
            window.location.replace("/");
        }
        else
        {
            toast.error(responseData.errors);
        }
    }

    const handleValidation = () =>
    {
        let valid = true;
        let errors = {name: "", password: "", rePassword: "", email: ""};

        // Validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email))
        {
            errors.email = "Please enter a valid email address.";
            valid = false;
        }

        // Validate password
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,12}$/;
        if (!passwordRegex.test(formData.password))
        {
            errors.password = "Password must be 8-12 characters long,\n contain at least one uppercase letter, one lowercase letter, one special character, and one number.";
            valid = false;
        }

        // Validate first and last name
        if (!formData.firstName.trim() || !formData.lastName.trim())
        {
            errors.name = "First or last name cannot be empty.";
            valid = false;
        }

        // Checking if the passwords match
        if (formData.password !== formData.reEnteredPassword)
        {
            errors.rePassword = "Passwords do not match";
            valid = false;
        }

        if (!valid)
            toast.error("Incorrect input");

        setErrors(errors);
        return valid;
    }
    
    const signup = async () =>
    {
        // Validate form
        if (!handleValidation())
        {
            return;
        }

        console.log("Signup Function Executed", formData);
        let responseData;
        await fetch('https://golden-union-441323-d5.de.r.appspot.com/users/signup',
        {
            method: 'POST',
            headers: 
            {
                Accept: 'application/form-data',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        }).then((response) => response.json())
        .then((data) => responseData = data);

        if (responseData.success)
        {
            toast.success("Account created!");
            setState("Login");
        }
        else
        {
            toast.error(responseData.errors);
        }
    }

  return (
    <div className='loginsignup'>
        <div className="loginsignup-container">
            <h1>{state}</h1>
            <div className='loginsignup-fields'>

                {state === "Sign Up"
                ?
                    <div>
                        <div className='first-and-lastname-container'>
                            <input name='firstName' value={formData.firstName} onChange={changeHandler} type="text" placeholder='First Name' />
                            <input name='lastName' value={formData.lastName} onChange={changeHandler} type="text" placeholder='Last Name' />
                        </div>
                        <div>
                            {errors.name && <p className="error-message name-error-message">{errors.name}</p>}
                        </div>
                    </div>
                :
                    <></>
                }

                <input name='email' value={formData.email} onChange={changeHandler} type="email" placeholder='Email Address' />
                {errors.email && <p className="error-message">{errors.email}</p>}
                <div className='password-container'>
                    <input 
                        name='password' 
                        value={formData.password} 
                        onChange={changeHandler} 
                        type={passwordVisible ? 'text' : 'password'} 
                        placeholder='Password'
                    />
                    <span onClick={togglePasswordVisibility} className='password-toggle-icon'>
                        {passwordVisible ? <FaEyeSlash/> : <FaEye/>}
                    </span>
                </div>
                {errors.password && <div className="error-message">
                        <ul>
                            <li>Password must be 8-12 characters long</li>
                            <li>Should contain at least one uppercase letter, one lowercase letter, one special character, and one number.</li>
                        </ul>
                        </div>
                }

                {state === "Sign Up"
                ?
                    <div>
                        <input name='reEnteredPassword' value={formData.reEnteredPassword} onChange={changeHandler} type="password" placeholder='Re-enter Password' />
                        {errors.name && <p className="error-message name-error-message">{errors.name}</p>}
                    </div>
                :
                <></>
                }
            </div>
            <button onClick={() => 
                {
                    if (state === "Login")
                        login();
                    else
                        signup();
                }}>Continue</button>

            {state === "Sign Up"
            ?
                <p className='loginsignup-login'>Already have an account? <span onClick={() => setState("Login")}>Login here</span></p>
            :
                <p className='loginsignup-login'>Create an account? <span onClick={() => setState("Sign Up")}>Click here</span></p>
            }

            <div>
                <Link to='/forgotPassword'>Forgot password</Link>
            </div>

            <div className="loginsignup-agree">
                <input type="checkbox" name='' id='' />
                <p>By continuing, I agree to the terms of use & privacy policy.</p>
            </div>
        </div>
    </div>
  )
}
