import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './Testimonials.css';
import testimonial1 from '../Assets/Testimonials/Testimonial1.jpg';
import testimonial2 from '../Assets/Testimonials/Testimonial2.jpg';
import testimonial3 from '../Assets/Testimonials/Testimonial3.jpg';
import testimonial4 from '../Assets/Testimonials/Testimonial4.jpg';
import testimonial5 from '../Assets/Testimonials/Testimonial5.jpg';
import testimonial6 from '../Assets/Testimonials/Testimonial6.jpg';
import testimonial7 from '../Assets/Testimonials/Testimonial7.jpg';
import testimonial8 from '../Assets/Testimonials/Testimonial8.jpg';
import testimonial9 from '../Assets/Testimonials/Testimonial9.jpg';
import testimonial10 from '../Assets/Testimonials/Testimonial10.jpg';
import testimonial11 from '../Assets/Testimonials/Testimonial11.jpg';
import testimonial12 from '../Assets/Testimonials/Testimonial12.jpg';

export const Testimonials = () => 
{
    const settings = 
    {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    function SampleNextArrow(props)
    {
        const { className, style, onClick } = props;
        return (
            <div
            className={className}
            style={{ ...style, display: "block", background: "none" }}
            onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) 
    {
        const { className, style, onClick } = props;
        return (
            <div
            className={className}
            style={{ ...style, display: "block", background: "none" }}
            onClick={onClick}
            />
        );
    }

    return (
        <div className='testimonials'>
        <h1>CUSTOMER'S TESTIMONIALS</h1>
        <hr />
        <Slider {...settings}>
            <div>
                <img src={testimonial1} alt="Testimonial 1" />
            </div>
            <div>
                <img src={testimonial2} alt="Testimonial 2" />
            </div>
            <div>
                <img src={testimonial3} alt="Testimonial 3" />
            </div>
            <div>
                <img src={testimonial4} alt="Testimonial 4" />
            </div>
            <div>
                <img src={testimonial5} alt="Testimonial 5" />
            </div>
            <div>
                <img src={testimonial6} alt="Testimonial 6" />
            </div>
            <div>
                <img src={testimonial7} alt="Testimonial 7" />
            </div>
            <div>
                <img src={testimonial8} alt="Testimonial 8" />
            </div>
            <div>
                <img src={testimonial9} alt="Testimonial 9" />
            </div>
            <div>
                <img src={testimonial10} alt="Testimonial 10" />
            </div>
            <div>
                <img src={testimonial11} alt="Testimonial 11" />
            </div>
            <div>
                <img src={testimonial12} alt="Testimonial 12" />
            </div>
        </Slider>
        </div>
    );
}
