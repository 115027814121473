import React, { createContext, useEffect, useState } from 'react'

export const ShopContext = createContext(null);

// Create empty cart
const getDefaultCart = ()=>
{
    let cart = {};
    for (let index = 0; index < 300 + 1; index++) 
    {
        cart[index] = 0;
    }
    return cart;
}

export const ShopContextProvider = (props) => 
{
    const [all_product, setAll_Product] = useState([]);
    const [cartItems, setCartItems] = useState(getDefaultCart());

    useEffect(() =>
    {
        fetch('https://golden-union-441323-d5.de.r.appspot.com/products/allproducts')
        .then((response) => response.json())
        .then((data) => setAll_Product(data));

        if (localStorage.getItem('auth-token'))
        {
            fetch('https://golden-union-441323-d5.de.r.appspot.com/cart/getcart',
            {
                method: 'POST',
                headers:
                {
                    Accept: 'application/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('auth-token')}`,
                    'Content-Type': 'application/json',
                },
                body: "",
            }).then((response) => response.json())
            .then((data) => setCartItems(data));
        }
    },[])

    const addToCart = (itemId) =>
    {
        setCartItems((prev)=>({...prev,[itemId]:prev[itemId]+1}));

        if (localStorage.getItem('auth-token'))
        {
            fetch('https://golden-union-441323-d5.de.r.appspot.com/cart/addtocart',
            {
                method: 'POST',
                headers:
                {
                    Accept: 'application/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('auth-token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({"itemId": itemId}),
            })
            .then((response) => response.json())
            .then((data) => console.log(data));
        }
    }

    const removeFromCart = (itemId) =>
    {
        setCartItems((prev)=>({...prev,[itemId]:prev[itemId]-1}))

        if (localStorage.getItem('auth-token'))
        {
            fetch('https://golden-union-441323-d5.de.r.appspot.com/cart/removefromcart',
            {
                method: 'POST',
                headers:
                {
                    Accept: 'application/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('auth-token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({"itemId": itemId}),
            })
            .then((response) => response.json())
            .then((data) => console.log(data));
        }
    }

    const getTotalCartAmount = () =>
    {
        let totalAmount = 0;
        if (all_product.length > 0)
        {
            for(const item in cartItems)
            {
                if (cartItems[item] > 0)
                {
                    let itemInfo = all_product.find((product)=>product.id === Number(item));
                    totalAmount += itemInfo.new_price * cartItems[item];
                }
            }
            return totalAmount;
        }
    }

    const getTotalCartItems = () => 
    {
        let totalItem = 0;
        for (const productId in cartItems) 
        {
            if (cartItems.hasOwnProperty(productId)) 
            {
                totalItem += cartItems[productId];
            }
        }
        return totalItem;
    };
    
    const setCartQuantity = (productId, quantity) => 
    {
        const parsedQuantity = parseInt(quantity, 10); // Parse quantity to an integer
        setCartItems(prev => 
        {
            // Ensure productId exists in cartItems
            if (prev.hasOwnProperty(productId)) 
            {
                // Update quantity for the given productId
                return { ...prev, [productId]: parsedQuantity };
            } 
            else 
            {
                // If productId doesn't exist, do nothing
                return prev;
            }
        });
    };
    

    const contextValue = {getTotalCartItems, getTotalCartAmount, all_product, cartItems, addToCart, removeFromCart, setCartQuantity};
    return(
        <ShopContext.Provider value={contextValue}>
            {props.children}
        </ShopContext.Provider>
    )
}
