import React from 'react'
import './Breadcrum.css'
import arrow_icon from '../Assets/breadcrum_arrow.png'
import { Link } from 'react-router-dom';

export const Breadcrum = (props) => {
    const {product} = props;

  return (
    <div className='breadcrum'>
        <Link  to="/all"> SHOP </Link>
        <img src={arrow_icon} alt="" />
        <Link  to={`/${product.category}`}> {product.category} </Link>
        <img src={arrow_icon} alt="" />
        {product.name}
    </div>
  )
}
