import React from 'react'
import './CSS/About.css'
import founder_image from '../Components/Assets/founder_image.jpg'
import model_image from '../Components/Assets/exclusive_image.png'

export const About = () => 
{
    return (
        <div className='about'>
            <div>
                <h1> About Us </h1>
                <div className="about-container">
                    <div className="about-text">
                        <p>
                            At Aramot Skincare Cosmetics, we believe that everyone deserves
                            to feel confident in their own skin. That's why we are dedicated to providing high-quality
                            skincare products that will enhance your skin, bring out the inner glow, give you a flawless skin tone,
                            and give you that confidence you desire.
                            <br/>
                            <br/>
                            Our products helps you to get rid of skin pigmentation, dark spots, acne, and other skin issues.
                            Our products are safe, skin friendly, steroid-free, and hydroquinone-free.  
                        </p>
                    </div>
                    <div className="about-image">
                        <img src={model_image} alt=""/>
                    </div>
                </div>
            </div>

            <div>
                <h2> Our Story </h2>
                <div className="story-container">
                    <div className="story-image">
                        <img src={founder_image} alt=""/>
                    </div>
                    <div className="story-text">
                        <p>
                            Founded in 2006 by Mrs.Oluwakemi Azeez, Aramot Skincare Cosmetics was made out of
                            a passion for skincare and creating products that deliver real results. Before Aramot
                            Skincare was founded, for years, she went through issues of serious acne, pimples and spots, 
                            which affected her emotions and confidence. Finding solutions to these issues were tough, but she was determined.
                            <br/>
                            <br/>
                            Eventually with deep research, she was able to find a lasting solution. Ever since then,
                            she promised herself that she would help others find solutions to their skin issues and 
                            make sure they achieve their dreams in acquiring a flawless skintone, so they don't have to go through the same long and tideous 
                            process she went through.
                        </p>
                    </div>
                </div>
            </div>

            <div className='mission-section'>
                <h2> Our Mission </h2>
                <p>
                    We aim to provide you with natural and organic skin care and organic beauty products 
                    that work to visibly produce real results for even the most sensitive skin.

                    Our brand care for all skin issues,  we pay attention to giving the skin the right skin food that is it needed, 
                    Our brand does not support  skin bleaching, rather we help to emulate all skin problems and give clear, beautiful skintone.
                </p>
            </div>
            <hr/>
        </div>
    )
}
