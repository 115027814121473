import React, { useContext, useState } from 'react'
import './EmailContainer.css'
import { RecoveryContext } from '../../Pages/ForgotPassword';
import axios from 'axios';

export const EmailContainer = () => 
{
    const { setEmail, setPage, setOTP } = useContext(RecoveryContext);
    const [localEmail, setLocalEmail] = useState('');

    function nagigateToOtp() 
    {
        if (localEmail) 
        {
          const OTP = Math.floor(Math.random() * 9000 + 1000);
          console.log(OTP);
          setOTP(OTP);
    
          axios
            .post("https://golden-union-441323-d5.de.r.appspot.com/users/send_recovery_email", 
            {
              OTP,
              recipient_email: localEmail,
            })
            .then(() => setPage("otp"))
            .catch(console.log);
          return;
        }
        return alert("Please enter your email");
      }

    const handleClick = () => 
    {
        setEmail(localEmail);
        nagigateToOtp();
    };

    return (
        <div className='emailcontainer'>
            <div className="emailcontainer-details">
                <h1>Enter your email address</h1>
                <div>
                    <input 
                    name='email' 
                    type="text" 
                    placeholder='Email Address' 
                    value={localEmail}
                    onChange={(e) => setLocalEmail(e.target.value)} />

                    <button onClick={handleClick}>Done</button>
                </div>
            </div>
        </div>
    )
}
