import React, { useState, useRef, useContext, useEffect } from 'react';
import './OTPInput.css';
import { RecoveryContext } from '../../Pages/ForgotPassword';
import axios from 'axios';

export const OTPInput = () => {
    const [otpInput, setOTPInput] = useState(['', '', '', '']);
    const inputRefs = [useRef(), useRef(), useRef(), useRef()];

    const { email, otp, setPage } = useContext(RecoveryContext);
    const [timerCount, setTimer] = React.useState(60);
    const [disable, setDisable] = useState(true);



    function resendOTP() 
    {
        if (disable) return;
        axios
        .post("https://golden-union-441323-d5.de.r.appspot.com/users/send_recovery_email", 
        {
            OTP: otp,
            recipient_email: email,
        })
        .then(() => setDisable(true))
        .then(() => alert("A new OTP has succesfully been sent to your email."))
        .then(() => setTimer(60))
        .catch(console.log);
    }

    function verifyOTP(currentOtpInput) 
    {
        const otpValue = currentOtpInput.join('');
        if (parseInt(otpValue) === otp) 
        {
            setPage("reset");
            return;
        }
        alert(
            "The code you have entered is not correct, try again or re-send the link"
        );
        return;
    }

    useEffect(() => 
    {
        let interval = setInterval(() => 
        {
            setTimer((lastTimerCount) => 
            {
                lastTimerCount <= 1 && clearInterval(interval);
                if (lastTimerCount <= 1) setDisable(false);
                if (lastTimerCount <= 0) return lastTimerCount;
                return lastTimerCount - 1;
            });
        }, 1000); //each count lasts for a second

        //cleanup the interval on complete
        return () => clearInterval(interval);
    }, [disable]);

    const handleChange = (index, value) => 
    {
        if (!isNaN(value)) 
        {
            const newOTP = [...otpInput];
            newOTP[index] = value;
            setOTPInput(newOTP);

            // Move focus to the next input field if a number is entered
            if (index < 3 && value !== '') 
            {
                inputRefs[index + 1].current.focus();
            }
            else if (index === 3)
            {
                verifyOTP(newOTP); // Pass the latest OTP directly
            }
        }
    };

    const handleKeyDown = (index, event) => 
    {
        // Move focus to the previous input field on pressing backspace in an empty input field
        if (event.key === 'Backspace' && otpInput[index] === '' && index > 0) 
        {
            inputRefs[index - 1].current.focus();
        }
    };

    return (
        <div className='otpinput'>
            <div className="otp-card">
            <h2>Enter Verification Code</h2>
            <p>We've sent a verification code to your email {email}.  Please enter the code below:</p>
            <div className="otp-input-container">
                {otpInput.map((digit, index) => (
                <input
                    key={index}
                    ref={inputRefs[index]}
                    className="otp-input"
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                />
                ))}
            </div>
            <button className="resend-button" onClick={() => resendOTP()}>Resend Code</button>
            <p>Count: {timerCount}</p>
            </div>
        </div>
    );
};
