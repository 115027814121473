import React, { useContext, useState } from 'react'
import './Item.css'
import { Link } from 'react-router-dom'
import { ShopContext } from '../../Context/ShopContext';
import { useNavigate } from 'react-router-dom';

export const Item = (props) => 
{

    const { addToCart } = useContext(ShopContext);
    const [addedToCart, setAddedToCart] = useState(false);

    const navigate = useNavigate();

    const handleAddToCart = () => 
    {
        const token = localStorage.getItem('auth-token');

        if (!token)
        {
            navigate("/login");
            return;
        }

        addToCart(props.id);
        setAddedToCart(true);
    }

    return (
        <div className='item'>
            <Link to={`/product/${props.id}`}><img src={props.image} alt="" /></Link>
            <p>{props.name}</p>
            <div className="item-prices">
                <div className="item-price-new">
                    ₦{props.new_price}
                </div>
                <div className="item-price-old">
                    ₦{props.old_price}
                </div>
            </div>
            <div className='button-container'>
                {addedToCart ? 
                (
                    <div  className="added-to-cart">
                        <button onClick={handleAddToCart}>Added to cart</button>
                    </div>
                ) 
                : 
                (
                    <button onClick={handleAddToCart}>Add to cart</button>
                )}
            </div>
        </div>
    )
}
