import React, { useContext, useState } from 'react';
import './Reset.css'
import { RecoveryContext } from '../../Pages/ForgotPassword';

export default function Reset() 
{
    const { setPage } = useContext(RecoveryContext);
    const [localProfile, setLocalProfile] = useState(
        {
            newPassword: '',
            reEnteredPassword: ''
        }
    )
    const [error, setError] = useState('');

    const changePassword = async () =>
    {
        let responseData;
        await fetch(`https://golden-union-441323-d5.de.r.appspot.com/users/update/${localStorage.getItem('userId')}`,
        {
            method: 'PUT',
            headers:
            {
                Accept: 'application/form-data',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(localProfile),
        }).then((response) => response.json())
        .then((data) => responseData = data);

        if (responseData.success)
        {
            alert(responseData.success);

            // Go to the "recovered" page
            setPage('recovered');
        }
        else
        {
            alert(responseData.errors);
        }
    }

    const handleChange = (e) => 
    {
        setLocalProfile({...localProfile, [e.target.name]:e.target.value});
    };

    const handleSubmit = (e) => 
    {
        e.preventDefault();
        if (localProfile.newPassword === localProfile.reEnteredPassword) 
        {
            // Passwords match, proceed to change password
            changePassword();
        } 
        else 
        {
            // Passwords don't match, display error
            setError('Passwords do not match');
        }
    };

    return (
        <div className='reset'>
            <div className='reset-container'>
                <h2>Reset Password</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>New Password</label>
                        <input
                            type='password'
                            name='newPassword'
                            value={localProfile.newPassword}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label>Confirm New Password</label>
                        <input
                            type='password'
                            name='reEnteredPassword'
                            value={localProfile.reEnteredPassword}
                            onChange={handleChange}
                        />
                    </div>
                    {error && <div className='error'>{error}</div>}
                    <button type='submit'>Reset Password</button>
                </form>
            </div>
        </div>
    );
}
