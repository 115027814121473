import React from 'react'
import './CSS/Distributors.css'
import us_flag from '../Components/Assets/USFlag.png'
import uk_flag from '../Components/Assets/UKFlag.png'

export const Distributors = () => 
{
    return (
        <div className='distributors'>
            <h1> Distributors </h1>
            <div className='dist-container'>
                <img src={us_flag} alt="" className='dist-flag'/>
                <div className='dist-info'>
                    <h2>USA</h2>
                    <p>Madam Oluwatosin</p>
                    <p>+14438760461</p>
                </div>
            </div>
            <div className='dist-container'>
                <img src={uk_flag} alt="" className='dist-flag'/>
                <div className='dist-info'>
                    <h2>UK</h2>
                    <p>Samiramalik083@gmail.com</p>
                    <p>07884262332</p>
                </div>
            </div>
        </div>
    )
}
