import React from 'react'
import './Recovered.css'
import { Link } from 'react-router-dom'

export const Recovered = () => 
{
  return (
    <div className='recovered'>
        <h2>Password succesfully set!</h2>
        <Link to="/login">Go back to login page</Link>
    </div>
  )
}
