// PaymentForm.jsx
import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './PaymentForm.css';
import Select from 'react-select';
import toast from 'react-hot-toast';
import countryList from 'react-select-country-list';

export const PaymentForm = ({totalAmount, paymentItems, shippingCost}) => 
{
    const stripe = useStripe();
    const elements = useElements();
    const [nameOnCard, setNameOnCard] = useState('');
    const returnUrl = window.location.origin;
    const [billingDetails, setBillingDetails] = useState(
    {
        firstName: '',
        lastName: '',
        country: '',
        streetAddress: '',
        city: '',
        state: '',
        phone: '',
        email: '',
    });
    const [orderNotes, setOrderNotes] = useState('');
    const [userDetailsFetched, setUserDetailsFetched] = useState(false);

    const countryOptions = countryList().getData();

    const finalBillingDetails =
    {
        name: nameOnCard,
        email: billingDetails.email,
        phone: billingDetails.phone,
        address: 
        {
            city: billingDetails.city,
            country: billingDetails.country,
            line1: billingDetails.streetAddress,
            postal_code: 'Not included',
            state: billingDetails.state,
        }
    };

    const handleNameChange = (event) => 
    {
        setNameOnCard(event.target.value);
    };

    const handleBillingDetailsChange = (event) => 
    {
        const { name, value } = event.target;

        setBillingDetails({ ...billingDetails, [name]: value });
    };

    const handleCountryChange = (selectedOption) => 
    {
        setBillingDetails({ ...billingDetails, country: selectedOption.value });
    };

    const handleOrderNotesChange = (event) => 
    {
        setOrderNotes(event.target.value);
    };

    useEffect(() =>
    {
        setNameOnCard(`${billingDetails.firstName} ${billingDetails.lastName}`);
    }, [billingDetails.firstName, billingDetails.lastName]);

    const handleSubmit = async (event) => 
    {
        event.preventDefault();

        if (!stripe || !elements) 
        {
            // Stripe.js has not yet loaded.
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { paymentMethod, error } = await stripe.createPaymentMethod(
        {
            type: 'card',
            card: cardElement,
            billing_details: finalBillingDetails,
        });

        if (error) 
        {
            console.error(error.message);
        } 
        else 
        {
            // Send paymentMethod.id and returnUrl to the backend for further processing
            console.log(paymentMethod.id);
            console.log(returnUrl);
            const response = await fetch('https://golden-union-441323-d5.de.r.appspot.com/cart/payment', 
            {
                method: 'POST',
                headers: 
                {
                    'Authorization': `Bearer ${localStorage.getItem('auth-token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                {
                    payment_method_id: paymentMethod.id,
                    amount: totalAmount,
                    return_url: returnUrl,
                }),
            });
            const data = await response.json();

            if (data.success)
            {
                toast.success("Payment successful");
                const orderDetails = 
                {
                    customer: data.customerid,
                    orderDate: new Date(),
                    orderStatus: 'Pending',
                    orderNotes,
                    payment: 
                    {
                        method: 'Card',
                        status: 'Paid',
                        transactionId: paymentMethod.id,
                        amount: totalAmount,
                    },
                    products: paymentItems.map(item => (
                    {
                        productid: item.id,
                        name: item.name,
                        image: item.image,
                        sku: item.sku,
                        quantity: item.quantity,
                        price: item.price,
                        total: item.quantity * item.price,
                        options: item.options,
                    })),
                    shipping: 
                    {
                        address: 
                        {
                            street: billingDetails.streetAddress,
                            city: billingDetails.city,
                            state: billingDetails.state,
                            country: billingDetails.country,
                            zip: 'Not included',
                        },
                        method: 'Standard Shipping',
                        cost: 10, 
                        carrier: 'UPS', 
                        trackingNumber: 'Not provided', 
                    },
                    tax: 
                    {
                        amount: 0, 
                        breakdown: {},
                    },
                    discount: 
                    {
                        code: 'Not provided', 
                        amount: 0,
                    },
                };
                storeOrderDetails(orderDetails);
            
            }
        }
    };

    const storeOrderDetails = async (orderItem) => 
    {
        const token = localStorage.getItem('auth-token');

        if (token)
        {
            try 
            {
                const response = await fetch('https://golden-union-441323-d5.de.r.appspot.com/order/add_order', {
                    method: 'POST',
                    headers: 
                    {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(orderItem),
                });
                const data = await response.json();

                if (data.success)
                {
                    removeAllCartItems(token);
                }

            } 
            catch (error) 
            {
                console.error('Error storing order detail:', error);
            }
        }
    };

    const removeAllCartItems = async (token) =>
    {
        try
        {
            const response = await fetch('https://golden-union-441323-d5.de.r.appspot.com/cart/removeallfromcart',
            {
                method: 'POST',
                headers:
                {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();

            if (data.success)
            {
                console.log('All cart items removed successfully');
                window.location.href = '/account';
            }
            else
            {
                console.error('Failed to remove cart items: ', data.message);
            }
        }
        catch (error)
        {
            console.error('Error removing cart items: ', error);
        }
    }

    useEffect(() => 
    {
        if (!userDetailsFetched)
        {
            const token = localStorage.getItem('auth-token');
            if (token) 
            {
                // Fetch user data from the backend using the token
                fetch('https://golden-union-441323-d5.de.r.appspot.com/users/user_details',
                {
                    method: 'GET',
                    headers: 
                    {
                        'Authorization': `Bearer ${token}`,  // Properly formatted Authorization header
                        'Content-Type': 'application/json'  // Good practice to include this as well
                    }
                })
                .then(response => response.json())
                .then(data => 
                {
                    if (data.success)
                    {
                        const userData = {
                            firstName: data.firstName,
                            lastName: data.lastName,
                            country: countryOptions.find(option => option.label === data.country)?.value || '',
                            streetAddress: data.streetAddress,
                            city: data.city,
                            state: data.state,
                            phone: data.phone,
                            email: data.email,
                        };
                        setBillingDetails(userData);
                        setUserDetailsFetched(true);
                    } 
                    else 
                    {
                        console.error('Failed to fetch user data:', data.message);  // Log or handle errors
                    }
                })
                .catch(error => console.error('Error fetching user data:', error));
            }
        }
    }, [userDetailsFetched]);

    const generateWhatsAppMessage = () => 
    {
        const productDetails = paymentItems.map(item => `
            *Product:* ${item.name}\n
            *Quantity:* ${item.quantity}\n
            *Price:* ${item.price}\n`
        ).join("\n");
    
        const shippingAddress = `
        *Name:* ${billingDetails.firstName} ${billingDetails.lastName}
        *Street Address:* ${billingDetails.streetAddress}
        *City:* ${billingDetails.city}
        *State:* ${billingDetails.state}
        *Country:* ${billingDetails.country}
        *Phone:* ${billingDetails.phone}
        *Email:* ${billingDetails.email}
        `;
    
        return `Order Details:\n\n${productDetails}\nShipping Address:\n${shippingAddress}\nShipping Cost:\n${shippingCost}\n\nTotal amount: ${totalAmount}`;
    };

    const whatsappMessage = generateWhatsAppMessage();
    const whatsappLink = `https://wa.me/+2348076469264?text=${encodeURIComponent(whatsappMessage)}`;

    return (
        <form className="payment-form" onSubmit={handleSubmit}>
            <div className="billing-details">
                <h3>Billing Details</h3>
                <div className="form-group">
                    <label>First Name:</label>
                    <input type="text" name="firstName" value={billingDetails.firstName} onChange={handleBillingDetailsChange} />
                </div>
                <div className="form-group">
                    <label>Last Name:</label>
                    <input type="text" name="lastName" value={billingDetails.lastName} onChange={handleBillingDetailsChange} />
                </div>
                <div className="form-group country">
                    <label>Country:</label>
                    <Select 
                        name="country"
                        value={countryOptions.find(option => option.value === billingDetails.country)}
                        onChange={handleCountryChange}
                        options={countryOptions}
                        placeholder="Select Country"
                    />
                </div>

                 {billingDetails.country !== 'NG(temp)' ? (
                    <div>
                        <p className="no-delivery-message">Payment not currently available.</p>
                        <p className="no-delivery-message">For international delivery, contact us on whatsapp.</p>
                        <a 
                            href={whatsappLink} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            Click here
                        </a>
                    </div>
                ) : (
                    <>
                        <div className="form-group">
                            <label>Street Address:</label>
                            <input type="text" name="streetAddress" value={billingDetails.streetAddress} onChange={handleBillingDetailsChange} placeholder='House number and street name'/>
                        </div>
                        <div className="form-group">
                            <label>Town / City:</label>
                            <input type="text" name="city" value={billingDetails.city} onChange={handleBillingDetailsChange}/>
                        </div>
                        <div className="form-group">
                            <label>State:</label>
                            <input type="text" name="state" value={billingDetails.state} onChange={handleBillingDetailsChange} />
                        </div>
                        <div className="form-group">
                            <label>Phone:</label>
                            <input 
                                type="text"
                                name="phone"
                                placeholder="Enter phone number"
                                value={billingDetails.phone}
                                onChange={(e) => setBillingDetails({ ...billingDetails, phone: e.target.value })}
                            />
                        </div>
                        <div className="form-group">
                            <label>Email Address:</label>
                            <input type="email" name="email" value={billingDetails.email} onChange={handleBillingDetailsChange} />
                        </div>
                        <div className="additional-information">
                            <div className="form-group">
                                <label>Order Notes (optional):</label>
                                <textarea rows="4" value={orderNotes} onChange={handleOrderNotesChange}></textarea>
                            </div>
                        </div>
                        <div className="form-group">
                        <label>Name on Card:</label>
                        <input type="text" className="input-field" value={nameOnCard} onChange={(e) => setNameOnCard(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Card Number:</label>
                        <CardElement className="card-element" />
                    </div>
                    <button type="submit" className="pay-button" disabled={!stripe}>
                        Pay
                    </button>
                    </>
                )}
            </div>
        </form>
    );
};
