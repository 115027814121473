import React, { useContext, useEffect, useState } from 'react';
import './CSS/ShopCategory.css';
import { ShopContext } from '../Context/ShopContext';
import { Item } from '../Components/Item/Item';

export const ShopCategory = (props) => 
{
    const { all_product } = useContext(ShopContext);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortBy, setSortBy] = useState(''); // State variable for sorting
    const [currentPage, setCurrentPage] = useState(1);
    const [category, setCategory] = useState('all');

    const productsPerPage = 16;

    // Filtering products based on category and search query

    let filteredProducts;

    let notFound = false;

    if (props.category === "all")
    {
        // Show all products
        filteredProducts = all_product.filter(item =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

        if (!filteredProducts.length)
            notFound = true;
    }
    else
    {
        filteredProducts = all_product.filter(item =>
            item.category === props.category &&
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

        if (!filteredProducts.length)
            notFound = true;
    }

    // Sorting products based on selected sorting option
    if (sortBy === 'name')
    {
        filteredProducts.sort((a, b) => a.name.localeCompare(b.name));
    } 
    else if (sortBy === 'priceAsc') 
    {
        filteredProducts.sort((a, b) => a.new_price - b.new_price);
    } 
    else if (sortBy === 'priceDesc') 
    {
        filteredProducts.sort((a, b) => b.new_price - a.new_price);
    }

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    const handleSortChange = (e) => 
    {
        setSortBy(e.target.value);
    }

    const handleSearchChange = (e) =>
    {
        setCurrentPage(1);
        setSearchQuery(e.target.value);
    }

    const paginate = (pageNumber) => 
    {
        setCurrentPage(pageNumber);
        window.location.href = `${window.location.pathname}?page=${pageNumber}`;
    }

    useEffect(() =>
    {
        const params = new URLSearchParams(window.location.search);
        const pageParam = parseInt(params.get('page'));

        // Set current page to the page parameter from the URL
        if (props.category === category)
        {
            setCurrentPage(pageParam || 1);
        }
        else
        {
            setCurrentPage(1);
            setCategory(props.category);
        }
    }, [props.category, category]);

    return (
        <div className='shop-category'>
        <img className='shopcategory-banner' src={props.banner} alt="" />
        <div className="shopcategory-indexSort">
            <p>
            <span>Showing {indexOfFirstProduct + 1}-{indexOfLastProduct}</span> out of {filteredProducts.length} products
            </p>
            <div className='shopcategory-sort'>
            Sort by
            <select value={sortBy} onChange={handleSortChange}>
                <option value="">None</option>
                <option value="name">Name</option>
                <option value="priceAsc">Price: Low to High</option>
                <option value="priceDesc">Price: High to Low</option>
            </select>
            </div>
        </div>
        <div className="shopcategory-search">
            <input
            type="text"
            placeholder="Search products..."
            value={searchQuery}
            onChange={handleSearchChange}
            />
        </div>
        {notFound ? <div className='not-found'>Product not found</div> : <></>}
        <div className="shopcategory-products">
            {currentProducts.map((item, i) =>
            <Item key={i}
                id={item.id}
                name={item.name}
                image={item.image}
                new_price={item.new_price}
                old_price={item.old_price} />
            )}
        </div>
        <div className="shopcategory-pagination-container">
            <div className="shopcategory-pagination">
            {Array.from({ length: Math.ceil(filteredProducts.length / productsPerPage) }, (_, index) => (
                <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
                >
                {index + 1}
                </button>
            ))}
            </div>
        </div>
        </div>
    )
    }
