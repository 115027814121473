import React, { useEffect, useState } from 'react';
import './Address.css';
import Modal from '../Modal/Modal.jsx';
import { toast } from 'react-hot-toast';
import countryList from 'react-select-country-list';
import Select from 'react-select';

export const Address = () => 
{
    const [address, setAddress] = useState(
    {
        country: '',
        streetAddress: '',
        city: '',
        state: '',
        phone: ''
    });

    // To store the new address details before the user saves changes
    const [localAddress, setLocalAddress] = useState(
    {
        newCountry: '',
        newStreetAddress: '',
        newCity: '',
        newState: '',
        newPhone: ''
    });

    const changeHandler = (e) => 
    {
        console.log("Value: " + e.target.value);
        console.log("Local Address: " + localAddress);
        console.log(localAddress);

        setLocalAddress({ ...localAddress, [e.target.name]: e.target.value });
    };

    const [showModal, setShowModal] = useState(null); // 'address', or null

    useEffect(() => 
    {
        const token = localStorage.getItem('auth-token');
        if (token) 
        {
            fetch('https://golden-union-441323-d5.de.r.appspot.com/users/user_details', 
            {
                method: 'GET',
                headers: 
                {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data =>
            {
                if (data.success)
                {
                    setAddress(prev => ({ ...prev, country: data.country, streetAddress: data.streetAddress, city: data.city, state: data.state, phone: data.phone }));
                }
                else
                {
                    toast.error('Failed to fetch user data', data.message);
                }
            })
            .catch(error => console.error('Error fetching user data'));
        }
    }, []);
    

    const changeAddressDetails = async () => 
    {
        const response = await fetch(`https://golden-union-441323-d5.de.r.appspot.com/users/update/${localStorage.getItem('userId')}`,
        {
            method: 'PUT',
            headers:
            {
                Accept: 'application/form-data',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(localAddress),
        });

        const responseData = await response.json();

        if (responseData.success)
        {
            toast.success("Profile detail updated!\nRe-login to see your changes.");
        }
        else
        {
            toast.error("Error: " + responseData.errors);
        }
    };

    const countryOptions = countryList().getData();

    return (
        <div className='address'>
            <h1>Address</h1>
            <div className='address-container'>
                <div className='address-item'>
                    <label>Country:</label>
                    <span>{address.country}</span>
                </div>
                <hr />
                <div className='address-item'>
                    <label>Street Address:</label>
                    <span>{address.streetAddress}</span>
                </div>
                <hr />
                <div className='address-item'>
                    <label>Town / City:</label>
                    <span>{address.city}</span>
                </div>
                <hr />
                <div className='address-item'>
                    <label>State:</label>
                    <span>{address.state}</span>
                </div>
                <hr />
                <div className='address-item'>
                    <label>Phone:</label>
                    <span>{address.phone}</span>
                </div>
                <button onClick={() => setShowModal('address')}>Change address</button>
            </div>
            {showModal === 'address' && (
                <Modal title="Change your address" onClose={() => { setShowModal(null) }}>
                    <label>Country:</label>
                    <Select 
                        name="country"
                        value={countryOptions.find(option => option.value === address.country)}
                        onChange={changeHandler}
                        options={countryOptions}
                        placeholder="Select Country"
                    />
                    {localAddress.newCountry === "International" ? (
                        <p className="message">For international delivery, contact us on whatsapp.</p>
                    )
                    :
                    (
                        <>
                                <label>Street Address:</label>
                                <input name='newStreetAddress' value={localAddress.newStreetAddress} onChange={changeHandler} type="text" />
                                <label>Town / City:</label>
                                <input name='newCity' value={localAddress.newCity} onChange={changeHandler} type="text" />
                                <label>State:</label>
                                <input name='newState' value={localAddress.newState} onChange={changeHandler} type="text" />
                                <label>Phone:</label>
                                <input type="text" name="newPhone" value={localAddress.newPhone} onChange={changeHandler} />
                        </>
                    )}
                    <button onClick={() => { changeAddressDetails() }}>Save changes</button>
                </Modal>
            )}
        </div>
    );
}
