import React, { useState, useEffect } from 'react';
import './Orders.css';

export const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [expandedOrderIds, setExpandedOrderIds] = useState([]);

    useEffect(() => {
        fetchOrderDetails();
    }, []);

    const fetchOrderDetails = async () => {
        try {
            const response = await fetch('https://golden-union-441323-d5.de.r.appspot.com/order/get_order', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('auth-token')}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch order details');
            }

            const data = await response.json();
            setOrders(data.orders);
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
    };

    const toggleOrderDetails = (orderId) => {
        setExpandedOrderIds(prevState => 
            prevState.includes(orderId) 
                ? prevState.filter(id => id !== orderId) 
                : [...prevState, orderId]
        );
    };

    if (!orders) {
        return <div>Loading...</div>;
    }

    return (
        <div className="orders-container">
            <h2>Your Orders</h2>
            <div className="orders-scroll">
                {orders.length === 0 ? (
                    <p>No orders found</p>
                ) : (
                    <div className="orders-list">
                        {orders.map(order => (
                            <div key={order._id} className="order-card">
                                <div className="order-summary" onClick={() => toggleOrderDetails(order._id)}>
                                    <div className="order-header">
                                        <h3>Order ID: {order._id}</h3>
                                        <p>Date: {new Date(order.orderDate).toLocaleDateString()}</p>
                                        <p>Status: {order.orderStatus}</p>
                                        <p>Notes: {order.orderNotes}</p>
                                    </div>
                                </div>
                                {expandedOrderIds.includes(order._id) && (
                                    <div className="order-details">
                                        <div className="products-list">
                                            <h4>Products</h4>
                                            {order.products.map((product, index) => (
                                                <div key={index} className="product-item">
                                                    <img src={product.image} alt={product.name} />
                                                    <div>
                                                        <p>Name: {product.name}</p>
                                                        <p>SKU: {product.sku}</p>
                                                        <p>Price: ${product.price}</p>
                                                        <p>Quantity: {product.quantity}</p>
                                                        <p>Total: ${product.total}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="shipping-details">
                                            <h4>Shipping Details</h4>
                                            <p>Address: {order.shipping.address.line1}, {order.shipping.address.city}, {order.shipping.address.state}, {order.shipping.address.country}, {order.shipping.address.zip}</p>
                                            <p>Method: {order.shipping.method}</p>
                                            <p>Cost: ${order.shipping.cost}</p>
                                            <p>Carrier: {order.shipping.carrier}</p>
                                            <p>Tracking Number: {order.shipping.trackingNumber}</p>
                                        </div>
                                        <div className="payment-details">
                                            <h4>Payment Details</h4>
                                            <p>Method: {order.payment.method}</p>
                                            <p>Status: {order.payment.status}</p>
                                            <p>Transaction ID: {order.payment.transactionId}</p>
                                            <p>Amount: ${order.payment.amount}</p>
                                        </div>
                                        <div className="tax-details">
                                            <h4>Tax Details</h4>
                                            <p>Amount: ${order.tax.amount}</p>
                                            <p>Breakdown: {JSON.stringify(order.tax.breakdown)}</p>
                                        </div>
                                        <div className="discount-details">
                                            <h4>Discount Details</h4>
                                            <p>Code: {order.discount.code}</p>
                                            <p>Amount: ${order.discount.amount}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
