import React from 'react'
import './Hero.css'
import arrow_icon from '../Assets/arrow.png'

export const Hero = () => 
{
  return (
    <div className='hero'>
        <div className="hero-left">
            <h2>ARAMOT SKINCARE</h2>
            <div>
                <p>new</p>
                <p>collections</p>
                <p>available</p>
            </div>
            <div className="hero-latest-btn">
                <div>Latest Collection</div>
                <img src={arrow_icon} alt="" />
            </div>
        </div>
    </div>
  )
}
