import React, { createContext, useState } from 'react'
import { EmailContainer } from '../Components/EmailContainer/EmailContainer';
import { OTPInput } from '../Components/OTPInput/OTPInput';
import Reset from '../Components/Reset/Reset';
import { Recovered } from '../Components/Recovered/Recovered';


export const RecoveryContext = createContext();

export const ForgotPassword = () => 
{
    const [page, setPage] = useState("emailContainer");
    const [email, setEmail] = useState();
    const [otp, setOTP] = useState();

    function NavigateComponents() 
    {
        if (page === "emailContainer") return <EmailContainer />;
        if (page === "otp") return <OTPInput />;
        if (page === "reset") return <Reset />;
    
        return <Recovered />;
    }

    return (
        <RecoveryContext.Provider
            value ={{ page, setPage, otp, setOTP, setEmail, email }} 

        >
            <NavigateComponents/>
        </RecoveryContext.Provider>
    )
}
