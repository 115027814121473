import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './Navbar.css';
import './UserDropdown.css'
import logo from '../Assets/logo.png';
import cart_icon from '../Assets/cart_icon.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ShopContext } from '../../Context/ShopContext';
import nav_dropdown from '../Assets/dropdown_icon.png';
import user_dropdown from '../Assets/downward_arrow.png';
import { isTokenExpired } from '../../Utils/authUtils.js';
import { formatFirstName } from '../../Utils/usernameFormatter.js';


export const Navbar = () => 
{
    const [menu, setMenu] = useState('shop');
    const { getTotalCartItems } = useContext(ShopContext);
    const menuRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const [showUserModal, setShowUserModal] = useState(false);
    const [userName, setUserName] = useState('');

    const dropdown_toggle = (e) => 
    {
        menuRef.current.classList.toggle('nav-menu-visible');
        e.target.classList.toggle('open');
    };

    const closeMenu = () => 
    {
        menuRef.current.classList.remove('nav-menu-visible');
    };

    const checkTokenAndLogout = useCallback(() => 
    {
        const token = localStorage.getItem('auth-token');
        if (token && isTokenExpired(token)) 
        {
            localStorage.removeItem('auth-token');
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => 
    {
        checkTokenAndLogout();
    }, [checkTokenAndLogout]);

    const logout = () =>
    {
        localStorage.removeItem('auth-token');
        localStorage.removeItem('userId');
        navigate('/');
    }

    // Check if the current location is one of the shop-related pages
    const isShopPage = location.pathname === '/all' || location.pathname === '/faceProducts' || location.pathname === '/bodyProducts' || location.pathname === '/others';

    useEffect(() => 
    {
        const token = localStorage.getItem('auth-token');
        if (token) 
        {
            // Fetch user data from the backend using the token
            fetch('https://golden-union-441323-d5.de.r.appspot.com/users/user_details',
            {
                method: 'GET',
                headers: 
                {
                    'Authorization': `Bearer ${token}`,  // Properly formatted Authorization header
                    'Content-Type': 'application/json'  // Good practice to include this as well
                }
            })
            .then(response => response.json())
            .then(data => 
            {
                if (data.success)
                {
                    const formattedName = formatFirstName(data.firstName);
                    setUserName(formattedName);  // To make sure it is formatted
                } 
                else 
                {
                    console.error('Failed to fetch user data:', data.message);  // Log or handle errors
                }
            })
            .catch(error => console.error('Error fetching user data:', error));
        }
    }, []);

    return (
        <div className='navbar'>
            <div className="nav-logo">
                <Link to="/">
                    <img src={logo} alt="" />
                </Link>
                <Link to="/">
                    <p>Aramot Skincare Cosmetics</p>
                </Link>
            </div>
            <ul ref={menuRef} className="nav-menu">
                <li onClick={() => { setMenu('home'); closeMenu(); }}>
                    <Link className='navbar-tabs' to='/'>Home</Link>
                    {menu === 'home' ? <hr /> : null}
                </li>
                <li onClick={() => { setMenu('all'); closeMenu(); }}>
                    <Link className='navbar-tabs' to='/all'>Shop</Link>
                    {menu === 'all' ? <hr /> : null}
                </li>
                {isShopPage ? (
                    <>
                        <li onClick={() => { setMenu('faceProducts'); closeMenu(); }}>
                            <Link className='navbar-tabs' to='/faceProducts'>Face</Link>
                            {menu === 'faceProducts' ? <hr /> : null}
                        </li>
                        <li onClick={() => { setMenu('bodyProducts'); closeMenu(); }}>
                            <Link className='navbar-tabs' to='/bodyProducts'>Body</Link>
                            {menu === 'bodyProducts' ? <hr /> : null}
                        </li>
                        <li onClick={() => { setMenu('others'); closeMenu(); }}>
                            <Link className='navbar-tabs' to='/others'>Others</Link>
                            {menu === 'others' ? <hr /> : null}
                        </li>
                    </>
                ) : null}
                <li onClick={() => { setMenu('about'); closeMenu(); }}>
                    <Link className='navbar-tabs' to='/about'>About</Link>
                    {menu === 'about' ? <hr /> : null}
                </li>
                <li onClick={() => { setMenu('distributors'); closeMenu(); }}>
                    <Link className='navbar-tabs' to='/distributors'>Distributors</Link>
                    {menu === 'distributors' ? <hr /> : null}
                </li>
            </ul>
            <div className="nav-login-cart">
                {localStorage.getItem('auth-token') ? (
                        <div className="user-greeting"
                            onMouseEnter={() => setShowUserModal(true)}
                            onMouseLeave={() => setShowUserModal(false)}>
                            <Link to='/account'>
                                <span>Hello {userName}</span>
                                <img src={user_dropdown} alt="" />
                            </Link>
                            {showUserModal && (
                                <div className="user-modal">
                                    <Link to="/account"><button>View Account</button></Link>
                                    <button onClick={logout}>Logout</button>
                                </div>
                            )}
                        </div>
                ) : (
                    <Link to='/login'><button>Login</button></Link>
                )}
                <Link to='/cart'><img src={cart_icon} alt="" /></Link>
                <div className="nav-cart-count">{getTotalCartItems()}</div>
                <img className='nav-dropdown' onClick={dropdown_toggle} src={nav_dropdown} alt="" />
            </div>
        </div>
    );
};