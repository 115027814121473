import React, { useContext, useState } from 'react';
import './ProductDisplay.css';
import { ShopContext } from '../../Context/ShopContext';

export const ProductDisplay = (props) => {
    const { product } = props;
    const { addToCart } = useContext(ShopContext);
    const [addedToCart, setAddedToCart] = useState(false);

    // Function to handle adding item to cart
    const handleAddToCart = (productId) =>
    {
        addToCart(productId);
        setAddedToCart(true);
    };

    return (
        <div className='productdisplay'>
            <div className="productdisplay-left">
                <div className="productdisplay-img">
                    <img className='productdisplay-main-img' src={product.image} alt="" />
                </div>
            </div>
            <div className="productdisplay-right">
                <h1>{product.name}</h1>
                <div className="productdisplay-right-prices">
                    <div className="productdisplay-right-price-old">
                    ₦{product.old_price}
                    </div>
                    <div className="productdisplay-right-price-new">
                    ₦{product.new_price}
                    </div>
                </div>
                <div className="productdisplay-right-description">
                    Description of the product will go here
                </div>
                {addedToCart ? 
                (
                    <div className='added-to-cart'>
                        <button onClick={() => handleAddToCart(product.id)}>Added to cart</button>
                    </div>
                ) 
                : 
                (
                    <button onClick={() => handleAddToCart(product.id)}>ADD TO CART</button>
                )}
            </div>
        </div>
    );
};
