import React, { useEffect, useState } from 'react'
import './Popular.css'
import { Item } from '../Item/Item'

export const Popular = () => 
{
    const [popularProducts, setPopularProducts] = useState([]);

    useEffect(() =>
    {
        fetch('https://golden-union-441323-d5.de.r.appspot.com/products/popularinface')
        .then((response) => response.json())
        .then((data) => setPopularProducts(data));
    }, [])

    return (
    <div className='popular'>
        <h1>POPULAR FACE PRODUCTS</h1>
        <hr />
        <div className="popular-item">
            {popularProducts.map((item, i)=>
            {
                return <Item key = {i}
                        id = {item.id}
                        name = {item.name}
                        image = {item.image}
                        new_price = {item.new_price}
                        old_price = {item.old_price} />
            })}
        </div>
    </div>
    )
}
