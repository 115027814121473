import React, { useContext, useEffect, useState } from 'react';
import './CSS/Payment.css';
import { Link, useNavigate } from 'react-router-dom';
import { ShopContext } from '../Context/ShopContext';
import { PaymentForm } from '../Components/PaymentForm/PaymentForm';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import toast from 'react-hot-toast';

export const Payment = () => 
{
    const { getTotalCartAmount, all_product, cartItems } = useContext(ShopContext);
    const navigate = useNavigate();
    const [shippingCost, setShippingCost] = useState(3500);
    const [selectedShipping, setSelectedShipping] = useState('');

    // Shipping options with their prices
    const shippingOptions = [
        { label: "Mainland Lagos - ₦3500", value: 3500 },
        { label: "Island Lagos - ₦4500", value: 4500 },
        { label: "Ikorodu - ₦4500", value: 5000 },
        { label: "Opic - ₦4500", value: 5000 },
        { label: "Ibadun - ₦5500", value: 5500 },
        { label: "Ogun State - ₦5500", value: 5500 },
        { label: "All other regions - ₦9000", value: 9000 },
    ];

    const allPaymentItems = getAllPaymentItems();
    const isPaymentEmpty = (arr) => arr.every((item) => item === null);

    const orderArray = getCartItems(all_product, cartItems);
    
    const stripePromise = loadStripe("pk_test_51M6QSjJh31Mbv3YikHaLKfVGIXZnGyBpO9VtXVlzkPUulVuZEdC8URBQ0dnM8MTPRouPyzY7Sjz8CSTjO7qNuA6G00vhGF893a");

    function getAllPaymentItems() 
    {
        return all_product.map((product) =>
            {
            if (cartItems[product.id] > 0)
            {
                const productId = product.id;
                return (
                    <div key={productId} className="payment-item">
                        <div className="payment-product">
                            <img src={product.image} alt={product.name} className="payment-product-image" />
                            <div className="payment-product-details">
                                <p className="payment-product-name">{product.name}</p>
                                <p className="payment-product-price">₦{product.new_price}</p>
                                <p className="payment-product-quantity">Quantity: {cartItems[productId]}</p>
                                <p className="payment-product-total">Total: ₦{product.new_price * cartItems[productId]}</p>
                            </div>
                        </div>
                    </div>
                );
            }
            return null;
        });
    }

    function getCartItems(products, cartItems) 
    {
        if (!products || !cartItems) 
        {
            console.error("Missing required arguments: products and cartItems");
            return [];
        }

        return products.filter(product => cartItems[product.id] > 0).map(product => ({
            id: product.id,
            name: product.name,
            image: product.image,
            price: product.new_price,
            quantity: cartItems[product.id],
        }));
    }

    useEffect(() => 
    {
        const timer = setTimeout(() => 
        {
            if (isPaymentEmpty(allPaymentItems)) 
            {
                toast.error("Cart is empty");
                navigate("/all");
            }
        }, 2000);

        return () => clearTimeout(timer);
    }, [navigate, allPaymentItems]);

    const handleShippingChange = (event) => 
    {
        const selectedValue = parseInt(event.target.value);
        setShippingCost(selectedValue);
        setSelectedShipping(event.target.options[event.target.selectedIndex].text);
    };

    // Calculate total including shipping cost
    const totalAmount = getTotalCartAmount() + shippingCost;

    return (
        <div className="payment-container">
            <div className="payment-content">
                <div className="payment-promo">
                    <p>If you have a promo code, enter it here:</p>
                    <div className="payment-promo-input">
                        <input type="text" placeholder="Enter promo code" />
                        <button className="payment-promo-button">Apply</button>
                    </div>
                </div>
                <div className="payment-items">
                    <h2 className="payment-heading">Your Cart</h2>
                    {!isPaymentEmpty(allPaymentItems) ? (
                        allPaymentItems
                    ) : (
                        <div className="payment-empty-cart">
                            <p>Your cart is empty</p>
                            <Link to="/all" className="payment-empty-cart-link">
                                Continue Shopping
                            </Link>
                        </div>
                    )}
                </div>
                <div className="payment-summary">
                    <h2 className="payment-heading">Order Summary</h2>
                    <div className="payment-summary-details">
                        <div className="payment-summary-row">
                            <p>Subtotal:</p>
                            <p>₦{getTotalCartAmount()}</p>
                        </div>
                        <div className="payment-summary-row">
                            <label htmlFor="shipping-select">Shipping:</label>
                            <select id="shipping-select" value={shippingCost} onChange={handleShippingChange}>
                                {shippingOptions.map(option => (
                                    <option key={option.label} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="payment-summary-row">
                            <p>Shipping Cost:</p>
                            <p>₦{shippingCost}</p>
                        </div>
                        <div className="payment-summary-total">
                            <p>Total:</p>
                            <p>₦{totalAmount}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="payment-right-side">
                <Elements stripe={stripePromise}>
                    <PaymentForm totalAmount={totalAmount} paymentItems={orderArray} shippingCost={shippingCost}/>
                </Elements>
            </div>
        </div>
    );
};