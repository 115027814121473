import p1_img from "./product_1.jpg";
import p2_img from "./product_2.jpg";
import p3_img from './product_3.jpg'
import p4_img from './product_4.jpg'

let data_product = [
  {
    id: 1,
    name: "Pimples cleanser",
    image: p1_img,
    new_price: 8500,
    old_price: 0,
  },
  {id:2,
    name: "Acne and Pimples Cleanser",
    image: p2_img,
    new_price: 8500,
    old_price: 0,
  },
  {id:3,
    name: "Tonic toner cleanser",
    image: p3_img,
    new_price: 9000,
    old_price: 0,
  },
  {id:4,
    name: "Mild toner cleanser",
    image: p4_img,
    new_price: 9000,
    old_price: 0,
  },
];

export default data_product;
