import React, { useEffect, useState } from 'react';
import './CSS/Account.css';
import { Profile } from '../Components/Profile/Profile';
import { Address } from '../Components/Address/Address';
import { useNavigate } from 'react-router-dom';
import { Orders } from '../Components/Orders/Orders';

export const Account = () => 
{
    const [activeTab, setActiveTab] = useState('orders');
    const navigate = useNavigate();
    

    useEffect(() =>
    {
        const token = localStorage.getItem('auth-token');

        if (!token)
        {
            navigate("/login");
        }
    }, [navigate]);

    const getContent = () => 
    {
        switch (activeTab) 
        {
        case 'orders':
            return <Orders></Orders>;
        case 'profile':
            return <Profile></Profile>;
        case 'address':
            return <Address></Address>;
        default:
            return <div>Welcome to your account!</div>;
        }
    };

    return (
        <div className='account'>
        <div className='sidebar'>
            <ul>
            <li onClick={() => setActiveTab('orders')}>Your Orders</li>
            <hr/>
            <li onClick={() => setActiveTab('profile')}>Profile</li>
            <hr/>
            <li onClick={() => setActiveTab('address')}>Your Address</li>
            </ul>
        </div>
        <div className='content'>
            {getContent()}
        </div>
        </div>
    );
}
