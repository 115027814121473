import React, { useContext } from 'react';
import './CartItems.css';
import { ShopContext } from '../../Context/ShopContext';
import remove_icon from '../Assets/cart_cross_icon.png';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

export const CartItems = () => 
{
    const { getTotalCartAmount, all_product, cartItems, removeFromCart, setCartQuantity } = useContext(ShopContext);

    
    let allCartItems = getAllCartItems();
    const isCartEmpty = (arr) => arr.every((item) => item === null);

    function getAllCartItems()
    {
        return all_product.map((e) => 
            {
                if (cartItems[e.id] > 0) 
                {
                    const productId = e.id;

                    return (
                        <div key={e.id}>
                            <div className="cartitems-format cartitems-format-main">
                                <img src={e.image} alt="" className='carticon-product-icon' />
                                <p>{e.name}</p>
                                <p>₦{e.new_price}</p>
                                <input
                                    type="number"
                                    className="cartitems-quantity"
                                    value={cartItems[e.id]}
                                    onChange={(e) => setCartQuantity(productId, e.target.value)}
                                />
                                <p>₦{e.new_price * cartItems[e.id]}</p>
                                <img
                                    className='cartitems-remove-icon'
                                    src={remove_icon}
                                    onClick={() => { removeFromCart(e.id) }}
                                    alt=""
                                />
                            </div>
                            <hr />
                        </div>
                    );
                }
                
                return null;
            })
    }

    const handlePaymentLinkClick = () =>
    {
        allCartItems = getAllCartItems();
        if (isCartEmpty(allCartItems))
        {
            toast.error("Cart is empty");
        }
        else
        {
            window.location.href = '/payment';
        }
    }

    return (
        <div className='cartitems'>
            <div className="cartitems-format-main">
                <p>Products</p>
                <p>Title</p>
                <p>Price</p>
                <p>Quantity</p>
                <p>Total</p>
                <p>Remove</p>
            </div>
            <hr />
            {!isCartEmpty(allCartItems) 
            ? 
                getAllCartItems() 
            : 
                <div className='cart-empty-text'>
                    Cart is empty
                    <Link to='/all'>
                        <button>Go to Shop</button>
                    </Link>
                </div>
            }
            <div className="cartitems-down">
                <div className="cartitems-total">
                    <h1>Cart Totals</h1>
                    <div>
                        <div className="cartitems-total-item">
                            <p>Subtotal</p>
                            <p>₦{getTotalCartAmount()}</p>
                        </div>
                        <hr />
                        <div className="cartitems-total-item">
                            <p>Shipping Fee</p>
                            <p>Free</p>
                        </div>
                        <hr />
                        <div className="cartitems-total-item">
                            <h3>Total</h3>
                            <h3>₦{getTotalCartAmount()}</h3>
                        </div>
                    </div>
                        <button onClick={handlePaymentLinkClick}>PROCEED TO CHECKOUT</button>
                </div>
            </div>
        </div>
    );
};
