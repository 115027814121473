import React, { useState, useEffect } from 'react';
import './ProgressBar.css'; // Import CSS for styling

const ProgressBar = () => 
{
  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => 
  {
    const updateScrollPercentage = () => 
    {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const calculatedScrollPercentage = (scrollTop / scrollHeight) * 100;
      setScrollPercentage(calculatedScrollPercentage);
    };

    window.addEventListener('scroll', updateScrollPercentage);
    return () => 
    {
      window.removeEventListener('scroll', updateScrollPercentage);
    };
  }, []);

  return <div className="progress-bar" style={{ width: `${scrollPercentage}%` }} />;
};

export default ProgressBar;
