
import './App.css';
import { Navbar } from './Components/Navbar/Navbar';
import  {BrowserRouter, Routes, Route} from 'react-router-dom';
import { ShopCategory } from './Pages/ShopCategory';
import { Product } from './Pages/Product';
import { Cart } from './Pages/Cart';
import { LoginSignup } from './Pages/LoginSignup';
import { Footer } from './Components/Footer/Footer';
import all_products_banner from './Components/Assets/banner_all_products.png';
import face_products_banner from './Components/Assets/banner_face_products.png';
import body_products_banner from './Components/Assets/banner_body_products.png';
import other_banner from './Components/Assets/banner_other.png';
import { ScrollToTopButton } from './Components/ScrollToTopButton/ScrollToTopButton';
import ProgressBar from './Components/ProgressBar/ProgressBar';
import { Home } from './Pages/Home';
import { About } from './Pages/About';
import { Distributors } from './Pages/Distributors';
import { ForgotPassword } from './Pages/ForgotPassword';
import { Account } from './Pages/Account';
import { Payment } from './Pages/Payment';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div>
        <Toaster/>
        <BrowserRouter>
        <Navbar/>
        <ProgressBar/>
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/all' element={<ShopCategory banner={all_products_banner} category="all"/>}/>
            <Route path='/faceProducts' element={<ShopCategory banner={face_products_banner} category="faceProducts"/>}/>
            <Route path='/bodyProducts' element={<ShopCategory banner={body_products_banner} category="bodyProducts"/>}/>
            <Route path='/others' element={<ShopCategory banner={other_banner} category="others"/>}/>
            <Route path='/product' element={<Product/>}>
            <Route path=':productId' element={<Product/>}/>
            </Route>
            <Route path='/cart' element={<Cart/>}/>
            <Route path='/login' element={<LoginSignup/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/distributors' element={<Distributors/>}/>
            <Route path='/forgotPassword' element={<ForgotPassword/>}/>
            <Route path='/account' element={<Account/>}/>
            <Route path='/payment' element={<Payment/>}/>
        </Routes>
        <ScrollToTopButton/>
        <Footer/>
        </BrowserRouter>
    </div>
  );
}

export default App;
