import React, { useEffect, useState } from 'react';
import './Profile.css';
import Modal from '../Modal/Modal.jsx';
import { combineFirstAndLastName } from '../../Utils/usernameFormatter.js';
import { toast } from 'react-hot-toast';

export const Profile = () => 
{
    const [profile, setProfile] = useState(
    {
        firstName: 'abc',
        lastName: 'xyz',
        email: 'abc@example.com',
        password: 'password123',
    });

    // To store the new profile details before the user saves changes
    const [localProfile, setLocalProfile] = useState(
        {
            newFirstName: '',
            newLastName: '',
            newEmail: '',
            newPassword: '',
            reEnteredPassword: ''
        }
    );

    const changeHandler = (e) =>
    {
        setLocalProfile({...localProfile, [e.target.name]:e.target.value});
    };

    const [showModal, setShowModal] = useState(null); // 'name', 'email', 'password', or null

    useEffect(() => 
    {
        const token = localStorage.getItem('auth-token');
        if (token) 
        {
            fetch('https://golden-union-441323-d5.de.r.appspot.com/users/user_details', {
                method: 'GET',
                headers: 
                {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => 
            {
                if (data.success) 
                {
                    setProfile(prev => ({ ...prev, firstName: data.firstName, lastName: data.lastName, email: data.email }));
                } 
                else 
                {
                    console.error('Failed to fetch user data', data.message);
                }
            })
            .catch(error => console.error('Error fetching user data'));
        }
    }, []);

    const changeProfileDetails = async () =>
    {
        let responseData;
        await fetch(`https://golden-union-441323-d5.de.r.appspot.com/users/update/${localStorage.getItem('userId')}`,
        {
            method: 'PUT',
            headers:
            {
                Accept: 'application/form-data',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(localProfile),
        }).then((response) => response.json())
        .then((data) => responseData = data);

        if (responseData.success)
        {
            toast.success("Profile detail updated!\nRe-login to see your changes.");
        }
        else
        {
            toast.error(responseData.errors);
        }
    };

    const changePassword = async () =>
    {
        // Checking if the passwords match
        if (localProfile.newPassword !== localProfile.reEnteredPassword)
        {
            toast.error("Passwords do not match");
            return;
        }

        changeProfileDetails();
    };

    return (
        <div className='profile'>
            <h1>Profile</h1>
            <div className='profile-container'>
                <div className='profile-item'>
                    <label>Name:</label>
                    <span>{combineFirstAndLastName(profile.firstName, profile.lastName)}</span>
                    <button onClick={() => setShowModal('name')}>Change name</button>
                </div>
                <hr />
                <div className='profile-item'>
                    <label>Email:</label>
                    <span>{profile.email}</span>
                    <button onClick={() => setShowModal('email')}>Change email</button>
                </div>
                <hr />
                <div className='profile-item'>
                    <label>Password:</label>
                    <span>{profile.password.replaceAll(/./g, '*')}</span>
                    <button onClick={() => setShowModal('password')}>Change password</button>
                </div>
            </div>
            {showModal === 'name' && (
                <Modal title="Change your name" onClose={() => {setShowModal(null)}}>
                    <label>First name:</label>
                    <input name='newFirstName' value={localProfile.newFirstName} onChange={changeHandler} type="text" />
                    <label>Last name:</label>
                    <input name='newLastName' value={localProfile.newLastName} onChange={changeHandler} type="text" />
                    <button onClick={() => {changeProfileDetails()}}>Save changes</button>
                </Modal>
            )}
            {showModal === 'email' && (
                <Modal title="Change your email" onClose={() => {setShowModal(null)}}>
                    <label>New email:</label>
                    <input name='newEmail' value={localProfile.newEmail} onChange={changeHandler} type="email" />
                    <button onClick={() => {changeProfileDetails()}}>Save changes</button>
                </Modal>
            )}
            {showModal === 'password' && (
                <Modal title="Change your password" onClose={() => {setShowModal(null)}}>
                    <label>New password:</label>
                    <input name='newPassword' value={localProfile.newPassword}  onChange={changeHandler} type="password" />
                    <label>Confirm new password:</label>
                    <input name='reEnteredPassword' value={localProfile.reEnteredPassword} onChange={changeHandler} type="password" />
                    <button onClick={() => {changePassword()}}>Save changes</button>
                </Modal>
            )}
        </div>
    );
}

